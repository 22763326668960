import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpHeader
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { Layout } from '../components/layout';
import { ContentWrapper, PageWrapper } from '../components/layout/wrappers';
import { UnderlinedLink } from '../components/links';
import { PageTitle, PrimaryText } from '../components/typography';
import { stateData } from '../data/content/licensing';

const SectionStyle = createOwnUpStyle({
  marginBottom: 16
});

const Section = createOwnUpComponent('div', SectionStyle);

const HeaderStyle = createOwnUpStyle({
  fontSize: 19,
  fontWeight: 'bold'
});

const Header = createOwnUpComponent(OwnUpHeader, HeaderStyle);

const Head = () => (
  <React.Fragment>
    <Section>
      <PageTitle variant="title">Licensing</PageTitle>
    </Section>
    <Section>
      <Header variant="section">Own Up State Disclosures</Header>
    </Section>
    <Section>
      <Header variant="section">
        RateGravity, Inc.(d.b.a. Own Up.) is not a lender, but retains certain mortgage broker
        licenses to comply with various state requirements.{' '}
        <PrimaryText>Please see a summary of our current licensure below.</PrimaryText>
      </Header>
    </Section>
  </React.Fragment>
);

const StateField = ({ children }: PropsWithChildren<{}>) =>
  children ? (
    <>
      <PrimaryText>{children}</PrimaryText>
      <br />
    </>
  ) : null;

const States = () => (
  <React.Fragment>
    {stateData.map(({ state, description, code, disclaimer }, i) => (
      <Section key={i}>
        <Header variant="section">{`${state}:`}</Header>
        <StateField>{description}</StateField>
        <StateField>{code}</StateField>
        <StateField>{disclaimer}</StateField>
      </Section>
    ))}
  </React.Fragment>
);

const Final = () => (
  <React.Fragment>
    <Section>
      <Header variant="section">RateGravity Federal Disclosures</Header>
    </Section>
    <Section>
      <PrimaryText>
        NMLS#:
        <br />
        1450805
      </PrimaryText>
    </Section>
    <UnderlinedLink
      style={{ fontSize: 21 }}
      href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/1450805"
      target="_blank"
      rel="noopener"
    >
      To visit the NMLS consumer access page please click here.
    </UnderlinedLink>
  </React.Fragment>
);

export const Licensing = () => (
  <Layout>
    <PageWrapper>
      <ContentWrapper>
        <Head />
        <States />
        <Final />
      </ContentWrapper>
    </PageWrapper>
  </Layout>
);

export default Licensing;
