import React from 'react';
import { UnderlinedLink } from '../../../components/links';

// alphabetical order
export const stateData = [
  {
    state: 'Arizona',
    description: 'Licensed by the Arizona Department of Insurance and Financial Institutions',
    code: 'License No. MB-1021912'
  },
  {
    state: 'California',
    description: 'California Department of Real Estate, Real Estate Corporation',
    code: 'License Endorsement #02134434'
  },
  {
    state: 'Colorado',
    description: (
      <>
        <span>Mortgage Company Registration – Regulated by the </span>
        <UnderlinedLink href="https://dre.colorado.gov/" target="_blank" rel="noreferrer">
          Division of Real Estate
        </UnderlinedLink>
      </>
    ),
    code: 'License No. 1450805'
  },
  {
    state: 'Connecticut',
    description: 'Licensed as a Mortgage Broker – Department of Banking',
    code: 'License No. MB-1450805'
  },
  {
    state: 'District of Columbia',
    description:
      'Licensed by the District of Columbia Department of Insurance, Securities and Banking',
    code: 'License No. MLB1450805'
  },
  {
    state: 'Florida',
    description: 'Licensed as a Mortgage Broker by the Office of Financial Regulation',
    code: 'License No. MBR2922'
  },
  {
    state: 'Georgia',
    description:
      'Georgia Residential Mortgage Licensee – Licensed as a Mortgage Broker/Processor by the Department of Banking and Finance',
    code: 'No. 67042'
  },
  {
    state: 'Illinois',
    description: 'Illinois Residential Mortgage',
    code: 'License No. MB.6761609'
  },
  {
    state: 'Indiana',
    description: 'SOS Loan Broker',
    code: 'License No. 1450805'
  },
  {
    state: 'Maine',
    description: 'Licensed as a Loan Broker by the Bureau of Consumer Credit Protection',
    code: 'License No. 1450805'
  },
  {
    state: 'Maryland',
    description:
      'Licensed by the Maryland of Labor, Office of the Commissioner of Financial Regulation',
    code: 'License No. 06-25073'
  },
  {
    state: 'Massachusetts',
    description: 'Mortgage Broker',
    code: 'License No. MB1450805'
  },
  {
    state: 'Michigan',
    description: 'Department of Insurance and Financial Services 1st Mortgage Broker License',
    code: 'License No. FL0022270'
  },
  {
    state: 'Minnesota',
    description: 'Licensed by the Commerce Department Financial Institutions Division',
    code: 'Residential Mortgage Originator License # MN-MO-1450805'
  },
  {
    state: 'New Hampshire',
    description: 'Licensed by the New Hampshire Banking Department',
    code: 'Mortgage Broker License No. 22638-MBR'
  },
  {
    state: 'New Jersey',
    description: 'New Jersey Residential Mortgage Broker',
    code: 'License NMLS ID # 1450805'
  },
  {
    state: 'North Carolina',
    code: 'Mortgage Broker License - B-198370'
  },
  {
    state: 'Ohio',
    description: 'Licensed by the Department of Commerce, Division of  Financial Institutions',
    code: 'Mortgage Lending Act Certificate of Registration RM.804688.000'
  },
  {
    state: 'Pennsylvania',
    description: 'Licensed by the Department of Banking and Securities',
    code: 'Mortgage Broker License No. 70860'
  },
  {
    state: 'Rhode Island',
    description: 'Licensed by the Rhode Island Division of Banking',
    code: 'Loan Broker License No. 20193713LB'
  },
  {
    state: 'South Carolina',
    description: 'South Carolina Department of Consumer Affairs - Mortgage Broker License'
  },
  {
    state: 'Tennessee',
    code: 'Mortgage License No. 220781'
  },
  {
    state: 'Texas',
    code: 'NMLS No. 1450805',
    disclaimer: (
      <section aria-label="texas disclaimer">
        <span>
          CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A MORTGAGE BANKER OR A LICENSED MORTGAGE
          BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATOR SHOULD COMPLETE AND SEND A COMPLAINT FORM TO
          THE TEXAS DEPARTMENT OF SAVINGS AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN,
          TEXAS 78705. COMPLAINT FORMS AND INSTRUCTIONS MAY BE OBTAINED FROM THE DEPARTMENT’S
          WEBSITE AT{' '}
        </span>
        <UnderlinedLink href="https://www.sml.texas.gov" target="_blank" rel="noreferrer">
          WWW.SML.TEXAS.GOV
        </UnderlinedLink>
        <span>. A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT </span>
        <UnderlinedLink href="tel:18772765550">1-877-276-5550</UnderlinedLink>
        <span>
          . THE DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE PAYMENTS OF CERTAIN ACTUAL OUT OF
          POCKET DAMAGES SUSTAINED BY BORROWERS CAUSED BY ACTS OF LICENSED RESIDENTIAL MORTGAGE LOAN
          ORIGINATORS. A WRITTEN APPLICATION FOR REIMBURSEMENT FROM THE RECOVERY FUND MUST BE FILED
          WITH AND INVESTIGATED BY THE DEPARTMENT PRIOR TO THE PAYMENT OF A CLAIM. FOR MORE
          INFORMATION ABOUT THE RECOVERY FUND, PLEASE CONSULT THE DEPARTMENT’S WEBSITE AT{' '}
        </span>
        <UnderlinedLink href="https://www.sml.texas.gov" target="_blank" rel="noreferrer">
          WWW.SML.TEXAS.GOV
        </UnderlinedLink>
        <span>. Mortgage Company License No. 1450805.</span>
      </section>
    )
  },
  {
    state: 'Virginia',
    description: 'Virginia Broker License',
    code: 'License No. MC-7353'
  },
  {
    state: 'Wisconsin',
    description: 'Licensed by the Department of Financial Institutions',
    code: 'License No. 1450805BR'
  }
];
